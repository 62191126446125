import React, {Component, useEffect} from "react";

//import Select from 'react-select'
import {useLocation} from '@reach/router';
import {Link, navigate} from "gatsby"
//import {swipeTransition, clubs} from "../constants"
//import * as styles from '../styles/index.module.scss'

import Layout from '../components/layout'
import {GlobalDispatchContext, GlobalStateContext} from '../context/context'

const AccountPage = () => {

  const dispatch = React.useContext(GlobalDispatchContext);
  const state = React.useContext(GlobalStateContext);

  return (

    <Layout pageTitle="Home Page">

<div className="container">
	<div className="row">
		<div className="col-md-12">
			
			<div className="container">
				<div className="row justify-content-center align-items-center">
					<div className="col-md-9">
						<div className="user-content text-center block-padding-medium">
							<h1>My Account</h1>
							<p>lorem</p>
						</div>
					</div>
				</div>

        
			</div>
      </div>
      </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-align-center">
          <h2>My saved companies</h2>

          </div>
        </div>
      </div>
    </Layout>
  ) 
}
export default AccountPage